<section class="price__area pt-60 mb-60">
  <div class="container">
    <div class="row mb-20">
      <div class="col-xxl-4 offset-xxl-4">
        <div class="section__title-wrapper text-center">
          <h2 class="section__title text-primary">Course <span class="yellow-bg yellow-bg-big">Fees<img
            src="assets/img/shape/yellow-bg.png" alt=""></span></h2>
          <p *ngIf="course?.newBatch?.flag" class="text-secondary">Everything Included, No Hidden or Surprise Charges</p>
        </div>
      </div>
    </div>
    <div *ngIf="course?.newBatch?.flag">
      <ng-template ngFor let-fees [ngForOf]="course?.feeDetails">
        <div class="row align-items-center mb-20">
          <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
            <div *ngIf="fees.support!=''" class="mb-20 text-center pt-0">
              <h4 class="animate__animated animate__pulseIn animate__infinite">
                Once you join us, you won't need to seek out additional resources elsewhere. We provide you complimentary Doubt Clearing Sessions, held monthly for {{ fees.support }}.
              </h4>
              <p>Sessions are scheduled for the final Sunday of each month.</p>
            </div>
            <div [ngClass]="{'_animate_pulse_black' : fees.payment.limitedTime}" class="border-5 border-gray border">
              <div class="price__item grey-bg p-relative shadow-sm">
                <div class="row align-items-center">
                  <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-5 align-items-start align-self-start">
                    <div *ngIf="fees.payment.limitedTime" class="price__offer">
                      <span>Limited Time</span>
                    </div>
                    <div class="price__head">
                      <h3>{{course?.title}}</h3>
                      <!--<h4 class="animate__animated animate__infinite animate__pulseIn">{{course?.subtitle}}</h4>-->
                      <p>{{course?.description}}</p>
                    </div>
                    <div class="price__tag">
                      <span class="text-green text-40 font-weight-700 p-5">{{fees.price}} <s
                        class="text-25 text-red font-weight-400">({{fees.oldPrice}})</s></span>
                    </div>
                  </div>
                  <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-5">
                    <div class="price__features">
                      <ul>
                        <li><i class="icon_check"></i>Classes: Online</li>
                        <!-- <li><i class="icon_check"></i>Starts From: {{ course?.newBatch?.startDate }}</li> -->
                        <li><i class="icon_check"></i>Recording: Available</li>
                        <li><i class="icon_check"></i>Duration: {{fees.duration}}</li>
                        <!--<li><i class="icon_check"></i>Support: {{fees.support}}</li>-->
                        <li><i class="icon_check"></i>Language: {{fees.language}}</li>
                        <li *ngIf="fees.forecasting.access"><i class="icon_check"></i>Forecasting
                          Service: {{fees.forecasting.validity}}</li>
                        <li *ngIf="fees.plugin.access"><i class="icon_check"></i>Plugin: {{fees.plugin.name}}
                          for {{ fees.plugin.validity }}</li>
                        <li><i class="icon_check"></i>EMI: Available</li>
                      </ul>
                    </div>
                  </div>
                  <div *ngIf="fees.payment.type=='ONLINE'" class="col-xxl-2 col-xl-2 col-lg-2 col-md-2 align-items-end align-self-end pt-30">
                    <p class="text-warning-emphasis text-center pb-20 animate__animated animate__infinite animate__pulseIn">By <b>Enrolling</b> in the course, you agree to the <a [routerLink]="['/terms-of-service']"><u>Term & Conditions</u></a></p>
                    <a [href]="getSafeUrl(fees.payment.link)" target="_blank" rel="noopener noreferrer"
                      class="e-btn e-btn-border">Enroll</a>
                  </div>
                </div>
                <div *ngIf="fees.payment.type=='UPI'" class="row align-items-center mt-5">
                  <div class="card bg-gray-100 card-body col-xxl-12 col-xl-12 col-lg-12 col-md-12 align-items-center align-self-end p-5 border-radius-0">
                    <p class="text-warning-emphasis text-center animate__animated animate__infinite animate__pulseIn">By <b>Enrolling</b> in the course, you agree to the <a [routerLink]="['/terms-of-service']"><u>Term & Conditions</u></a></p>
                    <!-- <h5 class="border-bottom pb-5">Pay using UPI</h5>
                    <h5>{{ fees.payment.upi }}</h5> -->
                    <h5 class="border-bottom border-top p-5 mt-3">Contact us for Enrollment</h5>
                    <p>info@intellxis.com</p>
                    <h5>WhatsApp & Telegram</h5>
                    <p>+91 9479999887</p>
                  </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </div>

    <div *ngIf="!course?.newBatch?.flag">
      <div class="row align-items-center">
        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
          <div class="price__item grey-bg p-relative shadow-sm text-center">
              <p class="text-primary font-weight-600 m-0 p-0 mb-10 text-20">Next Batch will start soon, you can contact us for the detail, you can also join our Telegram, WhatsApp and YouTube channel for more updates.</p>
              <p class="text-primary font-weight-600 m-0 p-0 text-18">Youtube Channel: <a target="_blank" href="https://www.youtube.com/@intellxis"><b>Join Now</b></a></p>
              <p class="text-primary font-weight-600 m-0 p-0 text-18">Telegram Channel: <a target="_blank" href="https://t.me/intellxis"><b>Join Now</b></a></p>
              <p class="text-primary font-weight-600 m-0 p-0 text-18 mb-10">WhatsApp Channel: <a target="_blank" href="https://whatsapp.com/channel/0029VaCTw1HIiRp1IrNDxT3f"><b>Join Now</b></a></p>
              <p class="text-black">You can also subscribe our <a href="/forecasting"><b>Educational Forecasting Service</b></a></p>
          </div>
        </div>
      </div>
    </div>

  </div>
</section>
