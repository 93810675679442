<main>
  <app-breadcrumb title="NRT Plugin" subtitle="NRT Plugin" backImage="assets/img/page-title/fnoplugin.jpg"></app-breadcrumb>

<!--  <i style="z-index: 1024; position: absolute; top: 240px; border-radius: 100%; right:375px;color: transparent;background-color: transparent"
     class="icon_check _animate_pulse"></i>
  <i style="z-index: 1024; position: absolute; top: 240px; border-radius: 100%; right:375px;color: transparent;background-color: transparent"
     class="icon_check _animate_pulse-3"></i>
  <i style="z-index: 1024; position: absolute; top: 240px; border-radius: 100%; right:375px;color: transparent;background-color: transparent"
     class="icon_check _animate_pulse-5"></i>-->

  <section class="event__area p-50">
    <div class="container">
      <div class="events__thumb w-img">
        <div class="events__details mb-35">
          <!--<h3 class="course__title text-danger text-center pb-20 animate__animated animate__infinite animate__pulseIn">
            Only for Intellxis' student who have enrolled for NRT Trading course
          </h3>-->
          <h3>NRT Plugin for TradingView</h3>
          <p>A Trading system that is designed to be flexible and adaptable to changing market conditions, and it is used to trade a variety of asset classes, including stocks, options, futures, and currencies.</p>
        </div>
      </div>
      <div class="row">
        <div class="col-xxl-8 col-xl-8 col-lg-8">
          <div class="events__wrapper">
            <a target="_blank" href="https://www.tradingview.com/x/NY2I33mh/" class="events__thumb w-img border-top border-bottom shadow-sm">
              <img src="assets/img/plugin/plugin-fno.png" alt="">
            </a>
            <ul class="text-muted pt-25 text-justify text-18">
              <li><h3>We offer following plugins under NRT plugin package.</h3></li>
            </ul>

            <ul class="text-muted pt-15 text-justify text-18">
              <li><i class="icon_check"></i> NRT Indicator</li>
              <li><i class="icon_check"></i> OBV Indicator</li>
              <li><i class="icon_check"></i> RS Indicator</li>
              <li><i class="icon_check"></i> eMACD Indicator</li>
              <li><i class="icon_check"></i> NRT Screener</li>
              <li><i class="icon_check"></i> Momentum Indicator</li>
            </ul>
          </div>
        </div>
        <div class="col-xxl-4 col-xl-4 col-lg-4">
          <div class="row" *ngFor="let plug of plugin.slice(2,3)" >
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12  col-sm-12">
              <div class="events__sidebar text-center pl-30 pr-30 ">
                <div class="_animate_pulse_black">
                  <div class="events__sidebar-widget white-bg mb-20">
                    <div class="events__sidebar-shape">
                      <img class="events-sidebar-img-2 _animate_dot_up" src="assets/img/shape/event-shape-2.png" alt="">
                      <img class="events-sidebar-img-3 _animate_dot_updown" src="assets/img/shape/event-shape-3.png" alt="">
                    </div>
                    <h3 class="pb-10">{{plug.period}}</h3>
                    <div class="events__info">
                      <div class="">
                        <h4>{{plug.price}} <p class="text-20"><s>{{plug.oldPrice}}</s></p></h4>
                      </div>
                      <p>{{plug.msg}}</p>
                      <p>This plugin package will not be useful to the subscriber unless they have completed the NRT course.</p>
                      <p class="text-black animate__animated animate__infinite animate__pulseIn">By doing the <b>Payment</b>, you are accepting the <a [routerLink]="['/terms-of-service']"><u>Term
                        & Conditions</u></a></p>
                      <div *ngIf="plug.upi" class="border p-5 text-secondary">
                        <!-- <h5 class="border-bottom pb-5">Pay using UPI</h5>
                        <h5 *ngFor="let upi of plug.upi.split(',')">{{ upi }}</h5> -->
                        <h5 class="border-bottom border-top bg-gray-100 p-5 mt-3">Contact us for More detail</h5>
                        <p>info@intellxis.com</p>
                        <h5>WhatsApp & Telegram</h5>
                        <p>+91 9479999887</p>
                      </div>
                      <!--<p>Contact us to Subscribe the Plugin</p>
                      p class="">{{plug.msg}}</p>
                      <p>By click on <b>Subscribe Now</b> button you are accepting the <a [routerLink]="['/terms-of-service']"><u>Term
                        & Conditions</u></a></p>
                      <a target="_blank" href="{{plug.payment}}"
                         class="btn align-items-end align-self-end border-radius-0 border-white-1 btn-primary">Subscr<span class="_animate_pulse"></span>ibe Now</a>-->
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="course__area">
    <div class="container">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-xxl-12 col-xl-12 col-lg-12 text-center">
            <div class="section__title-wrapper">
              <h2 class="section__title text-primary text-25">How to Plan F&O Trades using <span
                class="yellow-bg yellow-bg-sm">NRT Technology<img
                src="assets/img/shape/yellow-bg.png" alt=""></span></h2>
              <h4 class="mt-15 font-weight-500 pb-0 mb-0">All the trades shown in the videos are paper trades and are for educational purposes only.</h4>
            </div>
          </div>
        </div>

        <div class="row mt-30">
          <div class="col-xxl-12">
            <div class="course__slider swiper-container">
              <div class="swiper-wrapper">
                <swiper class="" [slidesPerView]="1" [spaceBetween]="30" [loop]="false"
                        [autoplay]="{ delay: 2500, disableOnInteraction: false }"
                        [pagination]="{ clickable: true, el: '.swiper-pagination' }"
                        [breakpoints]="{ '280': { slidesPerView: 1, spaceBetween: 30 },
                            '768': { slidesPerView: 3, spaceBetween: 30 },
                            '1200': { slidesPerView: 3, spaceBetween: 30 } }">
                  <ng-template *ngFor="let link of youtube" swiperSlide>
                    <a class="p-0 m-0"
                       [href]="getSafeUrl('https://www.youtube.com/live/'+link)" target="_blank"
                       data-hover-animate="fadeInDownSmall" data-hover-animate-out="fadeOutUpSmall"
                       data-hover-speed="350" data-hover-parent=".portfolio-item"
                       data-lightbox="image" title="Image">
                      <img class="card-img m-0 p-8 bg-primary"
                           [src]="getSafeUrl('https://i.ytimg.com/vi/'+link+'/mqdefault.jpg')" alt="youtube link">
                      <i style="position: absolute;top: 45%;left: 45%;"
                         class="play-btn arrow_triangle-right text-25"></i>.
                    </a>
                  </ng-template>
                </swiper>
                <!-- Add Pagination -->
                <div class="swiper-pagination"></div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="course__area">
    <div class="container border-top pt-50 pb-50">
      <h2 class="text-25 course__title text-center">Choose The Plan That's Right For You</h2>
      <!--<h3 class="text-25 course__title text-muted text-center pb-50">Prices will be different for Intellxis' NRT Students.</h3>-->
      <div class="row">
        <div *ngFor="let plug of plugin;let i = index" class="pl-30 pr-30 col-xxl-4 col-xl-4 col-lg-4">
          <div class="events__sidebar text-center">
            <div class="events__sidebar-widget white-bg mb-20">
              <div class="events__sidebar-shape">
                <img class="events-sidebar-img-2" src="assets/img/shape/event-shape-2.png" alt="">
                <img [ngClass]="{'_animate_dot_up': i % 2 !== 0, '_animate_dot_down2': i % 2 == 0}" class="events-sidebar-img-3" src="assets/img/shape/event-shape-3.png" alt="">
              </div>
              <h4 class="border-bottom pb-15">{{plug.period}}</h4>
              <div class="events__info">
                <div class="mb-25">
                  <h4>{{plug.price}}</h4>
                  <h5><s>{{plug.oldPrice}}</s></h5>
                </div>
                <p>{{plug.msg}}</p>
                <p>This plugin package will not be useful to the subscriber unless they have completed the NRT course.</p>
                <p class="text-black animate__animated animate__infinite animate__pulseIn">By doing the <b>Payment</b>, you are accepting the <a [routerLink]="['/terms-of-service']"><u>Term
                  & Conditions</u></a></p>
                <div *ngIf="plug.upi" class="border p-5 text-secondary">
                  <!-- <h5 class="border-bottom pb-5">Pay using UPI</h5>
                  <h5 *ngFor="let upi of plug.upi.split(',')">{{ upi }}</h5> -->
                  <h5 class="border-bottom border-top bg-gray-100 p-5 mt-3">Contact us for More detail</h5>
                  <p>info@intellxis.com</p>
                  <h5>WhatsApp & Telegram</h5>
                  <p>+91 9479999887</p>
                </div>
                <!--<p>Contact us to Subscribe the Plugin</p>
                <p>By click on <b>Subscribe Now</b> button you are accepting the <a [routerLink]="['/terms-of-service']"><u>Term
                  & Conditions</u></a></p>
                <a target="_blank" href="{{plug.payment}}"
                   class="btn align-items-end align-self-end border-radius-0 border-white-1 btn-primary">Subscr<span class="_animate_pulse"></span>ibe Now</a>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="course__area mb-50">
    <div class="container border-top pt-30">
      <h4><a [routerLink]="['/terms-of-service']">Term & Conditions</a></h4>
    </div>
  </section>
</main>
