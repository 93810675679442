<main>
  <app-breadcrumb title="Wave Chart Plugin" subtitle="Wave Chart Plugin"
                  backImage="assets/img/page-title/waveplugin.jpg"></app-breadcrumb>

  <!--  <i style="z-index: 1024; position: absolute; top: 240px; border-radius: 100%; right:375px;color: transparent;background-color: transparent"
       class="icon_check _animate_pulse"></i>
    <i style="z-index: 1024; position: absolute; top: 240px; border-radius: 100%; right:375px;color: transparent;background-color: transparent"
       class="icon_check _animate_pulse-3"></i>
    <i style="z-index: 1024; position: absolute; top: 240px; border-radius: 100%; right:375px;color: transparent;background-color: transparent"
       class="icon_check _animate_pulse-5"></i>-->

  <section class="event__area pt-30">
    <div class="container">
      <div class="events__wrapper">
        <div class="events__details mb-35">
          <h3 class="text-center">Wave Chart Plugin for TradingView</h3>
          <!--<p class="course__title text-danger text-center pb-20 animate__animated animate__infinite animate__pulseIn">
            This plugin will not mark the pattern itself. <br/> If you don't know NEoWave then this plugin may not be
            useful for you.
          </p>-->
          <p>An automated wave chart for wave analysis. This is an automated wave chart plotter that help you to find
            the current psychological trend and forecast the next one. Our Wave is a advanced version of elliott wave
            theory, which solve the lots of drawback's and issues' of elliott wave theory.</p>
        </div>
      </div>
      <div class="row">
        <div class="col-xxl-8 col-xl-8 col-lg-8">
          <div class="events__wrapper">
            <a href="https://www.tradingview.com/x/7cQTHGxr/" target="_blank" class="events__thumb w-img">
              <img src="assets/img/plugin/plugin-wave.png" alt="">
            </a>
            <a href="https://www.tradingview.com/x/b0FeHYSd/" target="_blank" class="events__thumb w-img">
              <img src="assets/img/plugin/plugin-wave2.png" alt="">
            </a>
          </div>
        </div>
        <div class="col-xxl-4 col-xl-4 col-lg-4 pt-50">
          <div class="row" *ngFor="let plug of plugin.slice(2,3)">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12  col-sm-12">
              <div class="events__sidebar text-center pl-30 pr-30 ">
                <div class="_animate_pulse_black">
                  <div class="events__sidebar-widget white-bg mb-20">
                    <div class="events__sidebar-shape">
                      <img class="events-sidebar-img-2 _animate_dot_up" src="assets/img/shape/event-shape-2.png" alt="">
                      <img class="events-sidebar-img-3 _animate_dot_updown" src="assets/img/shape/event-shape-3.png"
                           alt="">
                    </div>
                    <h3 class="border-bottom pb-10">{{ plug.period }}</h3>
                    <div *ngIf="plug.offer != ''" class="border-bottom pb-5"><p
                      class="m-0 p-0 animate__animated animate__infinite animate__pulseIn">
                      <b>{{ plug.offer != '' }}</b><br/>{{ plug.offerEnd }}</p></div>
                    <div class="events__info mt-5">
                      <div class="">
                        <p class="text-20"><s><b>{{ plug.oldPrice }}</b></s></p>
                        <h4>{{ plug.price }}</h4>
                      </div>
                      <!--<p class="">{{ plug.msg }}</p>-->
                      <p class="text-black animate__animated animate__infinite animate__pulseIn">By <b>Subscribing</b> the service, you are accepting the <a
                        [routerLink]="['/terms-of-service']"><u>Term
                        & Conditions</u></a></p>
                      <div *ngIf="plug.paymentType=='upi' || plug.paymentType=='both'" class="border p-5 text-secondary">
                        <!-- <h5 class="border-bottom pb-5">Pay using UPI</h5>
                        <h5 *ngFor="let upi of plug.upi.split(',')">{{ upi }}</h5> -->
                        <h5 class="border-bottom border-top bg-gray-100 p-5 mt-3">Contact us for More detail</h5>
                        <p>info@intellxis.com</p>
                        <h5>WhatsApp & Telegram</h5>
                        <p>+91 9479999887</p>
                      </div>
                      <div *ngIf="plug.paymentType=='link' || plug.paymentType=='both'" class="text-secondary p-5">
                        <a *ngIf="plug.paymentType=='link' || plug.paymentType=='both'" target="_blank" href="{{plug.paymentLink}}"
                           class="btn align-items-end align-self-end border-radius-0 mb-5 btn-primary">Subscr<span
                          class="_animate_pulse"></span>ibe Now</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="event__area p-50">
    <div class="container">
      <div class="row">
        <div class="col-xxl-12 col-xl-12 col-lg-12">
          <div class="events__wrapper">
            <div class="events__allow">
              <h3>Some Important Features</h3>
              <ul>
                <li class="text-18 text-gray-600"><i class="icon_check"></i> This indicator can draw wave chart from 5 Seconds to 5 Year or use any custom timeframe of your choice.</li>
                <li class="text-18 text-gray-600"><i class="icon_check"></i> Use any timeframe wave chart on any timeframe cash data, like use monthly cash data to draw 2.5 years or 5 years wave chart.</li>
                <li class="text-18 text-gray-600"><i class="icon_check"></i> Show multiple wave chart of different timeframe.</li>
                <li class="text-18 text-gray-600"><i class="icon_check"></i> Show performance matrix of different timeframe.</li>
                <li class="text-18 text-gray-600"><i class="icon_check"></i> Do the easy back testing with easy drag tool.</li>
                <li class="text-18 text-gray-600"><i class="icon_check"></i> Customize wave chart settings based on your requirement.</li>
                <li class="text-18 text-gray-600"><i class="icon_check"></i> Wave chart will be plotted on any type of charts like candlestick or bar chart.</li>
                <li class="text-18 text-gray-600"><i class="icon_check"></i> Custom settings to hide other charts, like you can hide bar or candlestick chart, while using wave analysis.</li>
                <li class="text-18 text-gray-600"><i class="icon_check"></i> Realtime plotting of wave chart from 5 seconds to 5 year.</li>
                <li class="text-18 text-gray-600"><i class="icon_check"></i> Show more historical wave chart.</li>
                <li class="text-18 text-gray-600"><i class="icon_check"></i> Show Demand & Supply base bars.</li>
              </ul>
            </div>
            <div class="events__allow mt-10 _animate_pulse_black row align-items-center border-1 border-bottom border-gray">
              <h3>Special Features</h3>
              <ul>
                <li class="text-18 text-gray-600"><i class="icon_check"></i> Plot automated channel for important support and resistance zones.</li>
                <li class="text-18 text-gray-600"><i class="icon_check"></i> These support and resistance zones may help you identify the endpoint of a leg, such as leg A, B, C, 1, 2, 3, and so on.</li>
              </ul>
            </div>
            <div class="events__allow mt-10">
              <h3>Features to be added in future update</h3>
              <ul>
                <li class="text-18 text-gray-600"><i class="icon_check"></i> Show Complexity levels.</li>
                <li class="text-18 text-gray-600"><i class="icon_check"></i> Show Price and Time.</li>
                <li class="text-18 text-gray-600"><i class="icon_check"></i> Show Starting point of patterns.</li>
                <li class="text-18 text-gray-600"><i class="icon_check"></i> Show Demand & Supply Zones.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="course__area">
    <div class="container">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-xxl-12 col-xl-12 col-lg-12 text-center">
            <div class="section__title-wrapper">
              <h2 class="section__title text-primary text-25">How to use Plugin and do <span
                class="yellow-bg yellow-bg-sm">Forecasts<img
                src="assets/img/shape/yellow-bg.png" alt=""></span></h2>
              <!--<h4 class="mt-15 font-weight-500 pb-0 mb-0">Embark on Your Learning Journey with Our Comprehensive Basic to Advanced and Mentorship Programs</h4>-->
            </div>
          </div>
        </div>

        <div class="row mt-30">
          <div class="col-xxl-12">
            <div class="course__slider swiper-container">
              <div class="swiper-wrapper">
                <swiper class="" [slidesPerView]="1" [spaceBetween]="30" [loop]="false"
                        [autoplay]="{ delay: 2500, disableOnInteraction: false }"
                        [pagination]="{ clickable: true, el: '.swiper-pagination' }"
                        [breakpoints]="{ '280': { slidesPerView: 1, spaceBetween: 30 },
                            '768': { slidesPerView: 3, spaceBetween: 30 },
                            '1200': { slidesPerView: 3, spaceBetween: 30 } }">
                  <ng-template *ngFor="let link of youtube" swiperSlide>
                    <a class="p-0 m-0"
                       [href]="getSafeUrl('https://www.youtube.com/watch?v='+link)" target="_blank"
                       data-hover-animate="fadeInDownSmall" data-hover-animate-out="fadeOutUpSmall"
                       data-hover-speed="350" data-hover-parent=".portfolio-item"
                       data-lightbox="image" title="Image">
                      <img class="card-img m-0 p-8 bg-primary"
                           [src]="getSafeUrl('https://i.ytimg.com/vi/'+link+'/mqdefault.jpg')" alt="youtube link">
                      <i style="position: absolute;top: 45%;left: 45%;"
                         class="play-btn arrow_triangle-right text-25"></i>.
                    </a>
                  </ng-template>
                </swiper>
                <!-- Add Pagination -->
                <div class="swiper-pagination"></div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="course__area">
    <div class="container border-top">
      <div class="row align-items-center border-1 border-bottom border-gray"> <!--_animate_pulse_black-->
        <div class="col-xxl-12 col-xl-12 col-lg-12 text-center">
          <br/>
          <h2 class="text-25 course__title text-center ">
            Exciting Offer! Refer your friends and unlock a whole month of FREE access to the NEoWave chart plugin!
            Don't miss out—spread the word and start earning your free access today!
          </h2>
          <br/>
        </div>
      </div>
    <br/>
      <h2 class="text-25 course__title text-center">Choose The Plan That's Right For You</h2>
      <!--<h3 class="course__title text-center pb-20 animate__animated animate__infinite animate__pulseIn">Only for students who have enrolled for F&O Trading course</h3>-->
      <div class="row" style="z-index: 1024">
        <div *ngFor="let plug of plugin;let i = index" class="p-30 col-xxl-4 col-xl-4 col-lg-4">
          <div class="events__sidebar text-center">
            <div class="events__sidebar-widget white-bg mb-20">
              <div class="events__sidebar-shape">
                <img class="events-sidebar-img-2" src="assets/img/shape/event-shape-2.png" alt="">
                <img [ngClass]="{'_animate_dot_up': i % 2 !== 0, '_animate_dot_down2': i % 2 == 0}"
                     class="events-sidebar-img-3" src="assets/img/shape/event-shape-3.png" alt="">
              </div>
              <h4 class="border-bottom pb-15">{{ plug.period }}</h4>
              <div *ngIf="plug.offer != ''" class="border-bottom pb-5">
                <p class="m-0 p-0 animate__animated animate__infinite animate__pulseIn">
                <b>{{ plug.offer }}</b><br/>{{ plug.offerEnd }}</p></div>
              <div class="events__info mt-5">
                <div class="mb-15">
                  <p class="text-20"><s><b>{{ plug.oldPrice }}</b></s></p>
                  <h4>{{ plug.price }}</h4>
                </div>
                <!--<p>{{ plug.msg }}</p>-->
                <p class="text-black mb-15 animate__animated animate__infinite animate__pulseIn">By <b>Subscribing</b> the service, you are accepting the <a
                  [routerLink]="['/terms-of-service']"><u>Term
                  & Conditions</u></a></p>
                <div *ngIf="plug.paymentType=='upi' || plug.paymentType=='both'" class="border p-5 text-secondary">
                  <!-- <h5 class="border-bottom pb-5">Pay using UPI</h5>
                  <h5 *ngFor="let upi of plug.upi.split(',')">{{ upi }}</h5> -->
                  <h5 class="border-bottom border-top p-5 bg-gray-100 mt-3">Contact us for More detail</h5>
                  <p>info@intellxis.com</p>
                  <h5>WhatsApp & Telegram</h5>
                  <p>+91 9479999887</p>
                </div>
                <div *ngIf="plug.paymentType=='link' || plug.paymentType=='both'" class="text-secondary pt-15">
                  <a *ngIf="plug.paymentType=='link' || plug.paymentType=='both'" target="_blank" href="{{plug.paymentLink}}"
                     class="btn align-items-end align-self-end border-radius-0 mb-5 btn-primary">Subscr<span
                    class="_animate_pulse"></span>ibe Now</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="course__area mb-50">
    <div class="container border-top pt-30">
      <h4><a [routerLink]="['/terms-of-service']">Term & Conditions</a></h4>
    </div>
  </section>

</main>
