import {Injectable, OnInit} from "@angular/core";

export interface FeeDetails {
  oldPrice: string;
  price: string;
  duration: string;
  language: string;
  offer: string;
  live: boolean;
  recording: boolean;
  forecasting: {
    access: boolean;
    validity: string;
  };
  plugin: {
    access: boolean;
    name: string;
    validity: string;
  };
  support: string;
  bestValue: boolean;
  payment: {
    type: string; // UPI, ONLINE
    link: string;
    upi: string
    limitedTime: boolean;
  }
}

export interface CourseFaqs {
  que: string;
  ans?: string;
  list?: string[];
}

export interface Testimonials {
  name: string;
  course: string;
  enrolled: string;
  review: string;
  photo: string;
}

export interface CourseI {
  id: number;
  img: string;
  uri: string;
  sessions: string;
  title: string;
  subtitle?: string,
  description: string;
  details: string;
  category: string;
  color: string;
  courseDelFaqs: CourseFaqs[];
  curriculum: string[];
  testimonials: Testimonials[];
  feeDetails: FeeDetails[];
  other?: any;
  newBatch: { flag: boolean, startDate: string };
}


@Injectable({
  providedIn: 'root'
})
export class Course implements OnInit {
  courses: CourseI[] = [
    // Price Action
    {
      id: 1,
      uri: "Price-Action",
      img: "assets/img/course/priceaction.jpg",
      sessions: "12+",
      title: "Price Action",
      description: "Decoding the Stock Market's Pulse for Beginners",
      category: "Basic of Stock Market",
      details: "Intellxis offers a comprehensive course that covers the basic fundamentals of the stock market. This course is ideal for beginners who want to learn about the stock market and understand how it works.<br/><br/>" +
        "The course covers the fundamental principles of the stock market, including the concepts of supply and demand, market structure, and market participants. You'll learn how to read stock charts, analyze price trends, and identify potential trading opportunities.<br/><br/>" +
        "Price Action is critical concepts in trading, and our course provides a comprehensive understanding of it. You'll learn how to identify support and resistance levels, understand market trends, and use these concepts to make informed trading decisions.<br/><br/>" +
        "Our course is designed to be interactive, engaging, and easy to understand. Our experienced instructors use real-time examples, case studies, and practical exercises to help you grasp the concepts effectively. We also provide you with access to our trading simulations, where you can apply your knowledge and gain valuable experience.<br/><br/>" +
        "By the end of this course, you'll have a solid understanding of the basics of the stock market. You'll be equipped with the necessary knowledge to start trading with confidence and develop a profitable trading strategy. Join us now and take the first step towards a successful trading career.",
      color: "sky-pink",
      courseDelFaqs: [
        {
          que: "What is \"Price Action\"?",
          ans: "Price Action is a vital concept in trading. It revolves around the study of the price movement of a security. Through this course, you will learn how to recognize support and resistance levels, discern market trends, and leverage these insights to make informed trading decisions."
        }, {
          que: "Who is the Intended Audience?",
          list: ["The course is ideal for beginners.", "Who are keen to learn about the stock market and its workings.", "No prior knowledge of the stock market is required."],
        }, {
          que: "How will this course benefits me?",
          ans: "This course will provide you with a robust understanding of the basics of the stock market and the essential concepts in trading. You will learn to make informed trading decisions, identify potential trading opportunities, and develop a profitable trading strategy."
        }, {
          que: "Is there any practical experience included in the course?",
          ans: "Yes, the course offers trading simulations where you can apply the concepts learned and gain hands-on trading experience."
        }, {
          que: "By the end of the course, what will I be able to do?",
          ans: "By the end of the course, you'll possess a comprehensive understanding of the stock market's basics. With the knowledge acquired, you'll be poised to start trading with confidence and craft a lucrative trading strategy."
        }
      ],
      curriculum: [
        "Basics of Equity, Commodity, Forex, and Currency Market.",
        "A detailed study of various investment instruments.",
        "In-depth analysis of different chart types and basic chart patterns.",
        "Introduction to Futures and Options.",
        "Fundamentals of Technical and Fundamental Analysis.",
        "Detailed understanding of Price Action and Demand and Supply.",
        "Insight into Market Psychology and Money Management.",
      ],
      testimonials: [{
        name: "Anjali Sharma",
        course: "Price Action",
        enrolled: "July 2022",
        review: "I was paralyzed with fear every time I thought about investing. This course gave me not just the skills but also the emotional fortitude to face the stock market. Understanding the concept of support and resistance levels was like unlocking a secret language for me. Now, every time I make a trade based on what I've learned, it feels like winning a personal battle. The elation I feel is indescribable.",
        photo: "assets/img/testimonial/testimonials.png",
      }, {
        name: "Neha Malhotra",
        course: "Price Action",
        review: "Before joining this course, I was like a ship lost at sea—constantly swaying with the tides of the stock market, but never really going anywhere. The Intellxis Price Action course acted like my North Star. Now, I don't just look at stock charts; I read them like a seasoned sailor reads the sea. The hands-on trading simulations moved me to tears of joy when I saw my virtual profits soaring. I can now say with utmost confidence and heartfelt gratitude, I'm no longer lost. Thank you, Intellxis!",
        photo: "assets/img/testimonial/testimonials.png",
        enrolled: "July 2022",
      }, {
        name: "Gaurav Kapoor",
        course: "Price Action",
        review: "Imagine finding a mentor, a guide, and a friend all rolled into one—that's what this course has been for me. The real-time examples and case studies felt like I was being handed down a family secret. The trading simulations stirred emotions of thrill and anticipation that I never thought I could feel about the stock market. I now look forward to every trading day with an enthusiasm that lights up my whole being.",
        photo: "assets/img/testimonial/testimonials.png",
        enrolled: "July 2022",
      }],
      feeDetails: [{
        oldPrice: "₹35,000",
        price: "₹20,000",
        duration: "3 Months",
        language: "English & Hindi",
        offer: "33%",
        live: true,
        recording: true,
        forecasting: {
          access: false,
          validity: "3 Months"
        },
        plugin: {
          access: false,
          name: "",
          validity: "",
        },
        support: "3 Months",
        bestValue: true,
        payment: {
          type: 'UPI', // UPI, ONLINE
          link: 'https://pages.razorpay.com/pl_KMRKXlkv3dUYGy/view',
          upi: 'intellxis@hdfcbank (INTELLXIS LLP)',
          limitedTime: true
        },
      }],
      other: [],
      newBatch: {flag: false, startDate: "Start Soon"},
    },
    // Fundamental Analysis
    {
      id: 2,
      uri: "Fundamental-Analysis",
      img: "assets/img/course/fundamentals.jpg",
      sessions: "12+",
      title: "Fundamental Analysis",
      description: "Deciphering Company Value: Dive into Fundamental Analysis",
      category: "Basic to Advance",
      color: "sky-blue",
      details: "At Intellxis, we offer a comprehensive course on Fundamental Analysis that provides unique strategies to help you select companies for investment. Our course covers a range of topics that are essential for understanding the financial health of a company, including financial statements, industry analysis, valuation techniques, and more.<br/><br/>" +
        "Our expert instructors will teach you how to analyze a company's financial statements, including balance sheets, income statements, and cash flow statements, to evaluate its financial performance and health. You will also learn how to conduct industry analysis to understand the competitive landscape and growth potential of a company's industry.<br/><br/>" +
        "In addition to these foundational skills, we offer unique strategies for selecting companies that can help you gain an edge in the market. Our instructors will show you how to use metrics such as price-to-earnings ratio, price-to-book ratio, and dividend yield to identify undervalued companies that have the potential for significant growth.<br/><br/>" +
        "Our course also covers the importance of qualitative factors such as management quality, competitive advantage, and growth prospects in selecting companies for investment. We provide tools and resources that can help you assess these factors and make informed investment decisions.<br/><br/>" +
        "At Intellxis, we believe that a thorough understanding of fundamental analysis is essential for long-term success in the stock market. Our course is designed to provide you with the knowledge and skills needed to evaluate companies and make informed investment decisions. Whether you are a novice or an experienced investor, our course can help you gain a deeper understanding of fundamental analysis and improve your investment strategy.",
      courseDelFaqs: [
        {
          que: "What is the \"Fundamental Analysis\" course at Intellxis?",
          ans: "The \"Fundamental Analysis\" course by Intellxis is a thorough training program aimed at teaching participants how to evaluate the financial health of companies and make informed investment decisions. The course covers everything from analyzing financial statements to understanding industry dynamics and unique strategies for picking promising investment opportunities."
        }, {
          que: "Who is this course designed for?",
          list: ["This course is suitable for anyone interested in the stock market, be it a beginner seeking foundational knowledge.", "An experienced investor aiming to refine their analytical skills and improve their investment strategy."],
        }, {
          que: "What will I learn about financial statements in this course?",
          ans: "Participants will learn to dissect key financial documents like balance sheets, income statements, and cash flow statements. This analysis is crucial to assess a company's financial performance, stability, and overall health."
        }, {
          que: "How does industry analysis fit into fundamental analysis?",
          ans: "Industry analysis helps investors understand the competitive landscape, opportunities, and challenges within a particular sector. By studying the industry, one can gauge the growth potential and risks associated with investing in companies operating within that space."
        }, {
          que: "Why is fundamental analysis vital for long-term stock market success?",
          ans: "Fundamental analysis offers insights into a company's intrinsic value and its potential growth or decline. It provides a rational basis for investment decisions, making it critical for those aiming for sustainable, long-term success in the stock market."
        }, {
          que: "How are the instructors at Intellxis qualified to teach this course?",
          ans: "The instructors at Intellxis are seasoned professionals with vast experience in the world of finance and investment. Their expertise ensures that you receive guidance rooted in practical knowledge and real-world applications."
        }
      ],
      curriculum: [
        "Grasp the core principles and importance of fundamental analysis in the investment domain.",
        "Learn to decode and assess crucial financial documents like balance sheets, income statements, and cash flow statements to gauge a company's financial health.",
        "Acquire skills to evaluate the competitive dynamics and growth potential within different sectors.",
        "Master various valuation methodologies essential in investment decision-making.",
        "Understand and apply critical metrics such as the price-to-earnings ratio, price-to-book ratio, and dividend yield.",
        "Utilize unique strategies to pinpoint undervalued companies with substantial growth prospects.",
        "Delve into the importance of non-quantitative elements like management caliber, competitive positioning, and future growth potential in the investment process.",
        "Get hands-on experience with tools and resources tailored to enhance your investment decision-making process.",
        "Embrace strategies rooted in comprehensive analysis to make informed investment choices for better returns.",
        "Whether a beginner or a seasoned investor, this course caters to all, providing insights that are adaptable to varying levels of experience and expertise.",
      ],
      testimonials: [{
        name: "Rajesh Kumar",
        course: "Fundamental Analysis",
        enrolled: "April 2022",
        review: "This course has been a revelation for me. Before enrolling, I used to invest based on gut feelings and tips from friends. Now, I feel like I've been given a set of analytical glasses to see the true financial health of companies. Learning how to dissect financial statements and conduct industry analysis was like learning a new language, and the instructors made it seem effortless. The strategies for selecting undervalued companies were pure gold. I'm already seeing a significant improvement in my investment portfolio, and it's all thanks to this course.",
        photo: "assets/img/testimonial/testimonials.png",
      }, {
        name: "Sneha Patel",
        course: "Fundamental Analysis",
        enrolled: "April 2022",
        review: "I've been trading for a while, but I knew there was something missing in my strategy. This course filled that gap. The insights I gained from dissecting financial statements and conducting industry analyses have given me a newfound confidence in my investment decisions. The practicality of the course is commendable; it's not just theory but actionable knowledge. The instructors' expertise is evident in the way they teach complex concepts so effortlessly. Fundamental analysis is no longer a mystery—it's a toolkit I use every day.",
        photo: "assets/img/testimonial/testimonials.png",
      }, {
        name: "Anand Sharma",
        course: "Fundamental Analysis",
        enrolled: "April 2022",
        review: "I consider this course as an investment in myself, and it's already paying dividends. Understanding financial statements and valuation techniques has been like unlocking a treasure chest. I can now sift through the noise of the market and spot hidden gems. The instructors' ability to simplify complex concepts and their commitment to student learning is commendable. Fundamental analysis is not just a skill; it's become a part of my daily life. Thank you, Intellxis, for this enlightening experience.",
        photo: "assets/img/testimonial/testimonials.png",
      },],
      feeDetails: [{
        oldPrice: "₹45,000",
        price: "₹35,000",
        duration: "3 Months",
        language: "English & Hindi",
        offer: "33%",
        live: true,
        recording: true,
        forecasting: {
          access: true,
          validity: "3 Months"
        },
        plugin: {
          access: false,
          name: "",
          validity: "",
        },
        support: "3 Months",
        bestValue: true,
        payment: {
          type: 'UPI', // UPI, ONLINE
          link: 'https://pages.razorpay.com/pl_KMRPoKtPxyd2av/view',
          upi: 'intellxis@hdfcbank (INTELLXIS LLP)',
          limitedTime: true
        },
      }],
      other: [],
      newBatch: {flag: false, startDate: "Start Soon"},
    },
    // F&O from Basic to Advance
    {
      id: 3,
      uri: "Advance-FnO",
      img: "assets/img/course/advancefno.jpg",
      sessions: "12+",
      title: "F&O from Basic to Advance",
      description: "Unlocking Futures & Options from Groundwork to Strategy Mastery",
      category: "Advance F&O",
      color: "blue",
      details: "Our course covers the basics of options trading, including terminology, pricing models, and trading strategies. We also delve into more advanced topics, such as implied volatility, option Greeks, risk management, and complex options strategies like iron condors, butterfly spreads, and calendar spreads. Our experienced instructors will guide you through each module, providing personalized support and feedback to ensure that you have a deep understanding of the concepts covered.",
      courseDelFaqs: [{
        que: "What is the \"Future & Option From Basic to Advance\" course?",
        ans: "The \"Future & Option From Basic to Advance\" course is a comprehensive training program offered by us, aimed at teaching participants about both the fundamentals and advanced topics of options trading. It covers a spectrum from basic terminology to complex strategies, ensuring a deep understanding of futures and options trading."
      }, {
        que: "Who is this course designed for?",
        list: [
          "The course is suitable for anyone interested in futures and options trading, whether you are a novice seeking foundational knowledge.",
          "An experienced trader looking to deepen your understanding of advanced strategies.",
          "Who are keen to learn about the F&O and Strategies.",
          "No prior knowledge of the Future & Option is required."],
      }, {
        que: "What are some of the basic concepts covered in the course?",
        ans: "At the outset, the course lays the foundation with topics like the basics of futures and options, including terminology, pricing models, and general trading strategies."
      }, {
        que: "How will this course help in managing positions in a volatile market?",
        ans: " A segment of the course is dedicated to teaching you how to manage positions during market turbulence. This is critical as markets can be unpredictable, and having the right skills can mean the difference between loss and profit."
      }, {
        que: "Is there any practical application or real-world examples in the course?",
        ans: "Yes, the course is designed not just to impart theoretical knowledge but also to provide hands-on experience and real-world examples. This ensures that participants can apply their learning in practical trading scenarios."
      },],
      curriculum: [
        "Introduction to Derivatives",
        "Terminology in Options Trading",
        "Pricing Models for Option Valuation",
        "Option Greeks (Delta, Gamma, Theta, Vega) and Their Application",
        "Effective Position Management Techniques",
        "Basic Option Strategies",
        "Advanced Option Strategies",
        "Risk Mitigation Strategies in Options Trading",
        "Real-World Examples of Options Strategies",
      ],
      testimonials: [{
        name: "Niraj Singh",
        course: "F&O Advance",
        enrolled: "April 2023",
        review: "The Advance F&O Trading course was challenging, but the instructors at Intellxis were always available to help. I feel more confident in my ability to trade in derivatives now.",
        photo: "assets/img/testimonial/testimonials.png",
      },],
      feeDetails: [{
        oldPrice: "₹45,000",
        price: "₹25,000",
        duration: "3 Months",
        language: "English & Hindi",
        offer: "33%",
        live: true,
        recording: true,
        forecasting: {
          access: false,
          validity: "3 Months"
        },
        plugin: {
          access: false,
          name: "",
          validity: "",
        },
        support: "3 Months",
        bestValue: true,
        payment: {
          type: 'UPI', // UPI, ONLINE
          link: 'https://pages.razorpay.com/pl_KMPe4ZhE2Y2pzo/view',
          upi: 'intellxis@hdfcbank (INTELLXIS LLP)',
          limitedTime: true
        },
      }],
      other: [],
      newBatch: {flag: false, startDate: "Start Soon"},
    },
    // Wave Analysis & Trading
    {
      id: 4,
      uri: "NEoWave-Analysis-Trading",
      img: "assets/img/course/waveanalysis.jpg",
      sessions: "12+",
      title: "NEoWave Analysis & Trading",
      subtitle: "1 Year Mentorship program",
      description: "Unlock Advanced Trading Strategies for Consistent Profits",
      category: "Mentorship Program",
      color: "green",
      details: "At Intellxis, we offer a comprehensive course on NEoWave Analysis and Trading that provides a deep understanding of wave theory and its application in the stock market. Our course covers a range of topics that are essential for understanding the complexities of the market and more.<br/>" +
        "<br/>In the Wave Analysis module, you will explore the history and development of wave theory, and gain a foundational understanding of wave analysis. You will learn to set up a wave chart and become proficient in interpreting time, price, and complexity on the chart. Our expert instructors will teach you to identify and mark impulsive and corrective waves on the chart, and conduct an in-depth study of various types of corrective and impulsive patterns. You will also practice identifying all patterns on a live chart and learn to perform forecasting on a live chart.<br/>" +
        "<br/>In the Wave Trading module, you will learn a unique method to trade Wave, which can help you improve your trading skills. Our instructors will teach you to assess the current market trend and master the art of placing stop loss in different market trends. You will acquire knowledge on trailing stop loss and learn to understand market behavior at the top and bottom. Our course will also teach you to gauge market momentum and make informed trading decisions.<br/>" +
        "<br/>You will also gain an understanding of market behavior at the top and bottom, and learn to gauge market momentum. Our expert instructors will provide you with the tools and knowledge needed to make informed trading decisions and achieve success in the market.<br/>" +
        "<br/>At Intellxis, we believe that a thorough understanding of Wave Analysis and Trading is essential for long-term success in the stock market. Our course is designed to provide you with the knowledge and skills needed to navigate the complexities of the market and make informed trading decisions. Whether you are a novice or an experienced trader, our course can help you gain a deeper understanding of Wave Analysis and Trading and improve your trading strategy.",
      courseDelFaqs: [
        {
          que: "What is the \"Wave Analysis\" Mentorship Program by Intellxis?",
          ans: "<b>Wave Analysis</b> course is a comprehensive training program provided by Intellxis that delves deep into Wave Analysis and its practical application in the stock market. It consists of two main modules: <b>Wave Analysis Basic to Advance</b> and <b>Wave Trading</b>, each focusing on a specific aspect of Wave Analysis and trading."
        }, {
          que: "How does the Wave Trading module differ from Wave Analysis?",
          ans: "While the Wave Analysis module focuses on understanding and interpreting wave patterns on charts, the Wave Trading module emphasizes the application of this knowledge in real trading scenarios. You'll learn unique wave trading methods, assess market trends, determine stop loss placements in various market conditions, understand market behavior at peak and trough, and gauge market momentum."
        }, {
          que: "Who is this course ideal for?",
          list: ["Whether you are a beginner in stock trading or an experienced trader, this course is designed to enrich your understanding of Wave Analysis and Trading.", "Who are keen to learn about the Wave Analysis.", "It's suitable for anyone looking to enhance their trading strategy by incorporating Wave Analysis.", "No prior knowledge of the stock market is required."],
        }, {
          que: "How will this course improve my trading?",
          ans: "The course is meticulously designed to provide a profound understanding of wave theory, which is essential for deciphering market patterns and behaviors. With this knowledge, you can make more informed trading decisions, understand market trends better, and develop a robust trading strategy that aligns with market momentum."
        }, {
          que: "Why is understanding market behavior at the top and bottom important?",
          ans: "Recognizing market behavior at peaks (tops) and troughs (bottoms) is vital as these are potential reversal points. By understanding these behaviors, traders can identify possible entry and exit points, manage risks effectively, and capitalize on trading opportunities."
        }, {
          que: "Does the course have any practical elements?",
          ans: "Yes, the course includes practical exercises like identifying patterns on live charts and performing forecasting on live charts, ensuring that you can apply the theoretical knowledge in real-time scenarios."
        }, {
          que: "Upon completion, how will this course benefit my trading strategy?",
          ans: "By the end of this program, you'll possess an in-depth understanding of wave theory and its application in trading. This will allow you to anticipate market moves, manage risks better, and craft a more informed and successful trading strategy."
        }
      ],
      curriculum: [
        "The history and evolution of Wave Theory.",
        "Fundamental concepts of Wave Analysis.",
        "Setting up and customizing Wave Charts.",
        "Interpreting Time, Price, and Complexity on charts.",
        "Identifying and marking Impulsive and Corrective waves.",
        "Detailed study of various Corrective and Impulsive patterns.",
        "Practical exercises on identifying patterns on live charts.",
        "Techniques for forecasting using live charts.",
        "A unique method to trade Wave, which can help you improve your trading skills.",
        "Learn to assess the current market TREND.",
        "Master the art of placing stop loss in different market trends.",
        "Acquire knowledge on trailing stop loss.",
        "Understand market behavior at the top and bottom.",
        "Learn to gauge market Momentum.",
      ],
      testimonials: [{
          name: "Avinash Waghmare",
          course: "Wave Analysis & Trading",
          enrolled: "June 2024",
          review: "Hello all, myself Avinash, and I am working in an IT company in a corporate setting.  <br/><br/>" +
            "I completed my NEoWave trading course from Pawan sir recently. " +
            "I just want to share my experience with you all. In the beginning, I started learning trading from watching YouTube videos, and that turned out to be a disaster. Then I realized that I had to find a mentor if I really wanted to excel in trading. I started learning from many known faces on YouTube and social media, but in the end, I got really disappointed with all of them." +
            "<br/><br/>I tried to learn many techniques like supply and demand, SMC concepts, volume profile, market profile, time cycle study, and many systems. All of those systems left me disappointed and with a feeling of inadequacy." +
            "<br/><br/>Then I got interested in Wave theory and started learning traditional Elliott Wave theory from many famous YouTubers, spending a lot of money on them—or, you could say, wasting my money. " +
            "Then I started to learn NEoWave theory, and I also learned NEoWave directly from Glenn Neely." +
            "<br/><br/>Finally, I got in contact with Pawan sir. When I learned from him, I felt I didn’t need to go anywhere else to learn anything new about NEoWave theory. Pawan sir has his own insights regarding NEoWave theory, and trust me, no one can teach you NEoWave better than him." +
            "<br/><br/>Many people just try to sell their courses, and many of them don’t even believe in what they are teaching. But Pawan sir is authentic in his knowledge, and he has created a complete system around NEoWave for accurate forecasting and trading. Pawan sir takes all the difficult concepts of wave theory and makes them easy to understand." +
            "<br/><br/>" +
            "I understand that many teachers are teaching trading on public forums, and it is very difficult to judge whom to trust. Believe me, I have learned from most of them and ended up disappointed.<br/>" +
            "<br/>" +
            "If you really want to understand market dynamics and how NEoWave chart patterns develop, Pawan sir is the best person to learn from.",
          photo: "assets/img/testimonial/testimonials.png",
        },{
          name: "Milind Gulavani",
          course: "F&O Trading",
          enrolled: "July 2023",
          review: "I have been trading in the market for the last 3-4 years and have done multiple courses. I have been a follower of elliott wave and techniques used in Advanced trading. I can confidently say that this is the best set of courses that I have come across over the years. Pawan has a very deep understanding of the market and the toolkit he has developed is amazing. Teaching is his passion and understanding the market deeply is his forte. This is a course for good duration of 3 months but Pawan doesnt rush into it unless everyone in the class is clear on concepts and applications of the same. He forces students to practise and ensures that they come up with doubts. The course moves at a very good pace giving enough room to understand, study, practise and clarify doubts. But one has to put in efforts to reap benefits. Pawan is there to help everyone and answers every single doubt, not only in the class but otherwise offline as well. He goes beyond the course duration to ensure the success of his students. It has been great taking these courses and I would recommend these courses to everyone who is serious about making money in the market.\n" +
            " However, I recommend to take these courses in sequence. Making money in the markets is not easy and it requires efforts so its better to learn Advanced trading course first. Start making money, understand the markets and then go to the elliott wave course. That way you would be able to give sufficient time to learn and start making money which is very important to boost the confidence in the markets. ",
          photo: "assets/img/testimonial/milind-july-2023.jpg",
        },{
          name: "Sunil Patel",
          course: "Wave Analysis & Trading",
          enrolled: "April 2023",
          review: "Learning Journey with Intellxis is amazing. I was searching in India who can teach me Wave Analysis easily from two years, but I never found any and at 1–2 place my money got wasted.<br/>" +
            "<br/>" +
            "But by learning three months on Wave Analysis with Intellxis, It is like super experienced.<br/>" +
            "During the course journey, Mr Pawan has given his more than 100% without bothering time and given his experience in the market with true honesty.<br/>" +
            "<br/>" +
            "Everyone feared about the Rules and multiple pattern of Wave Analysis, believe me After learning from Mr Pawan / Intellxis, you will be amazed, and it will be easy to mark the patterns.<br/>" +
            "<br/>" +
            "Course design, and the way of teaching is amazing.<br/>" +
            "I have started an Advanced F&O course also with Intellxis. I will give my feedback after completing it.<br/>" +
            "<br/>" +
            "Support team is also good, you will get a prompt response if you have any issue.",
          photo: "assets/img/testimonial/sunilpatel-april-2023.png ",
        },{
          name: "Manish Agrawal",
          course: "Wave Analysis & Trading",
          enrolled: "April 2023",
          review: "Thank you for a great course. Great presentation style with lots of opportunities to ask questions and talk about examples which all made for a really enjoyable and informative course. This has more than met my expectations. A wonderfully practical course—both personally and professionally.<br/>" +
            "Really enjoyed so, thank you!",
          photo: "assets/img/testimonial/manishagrawal-april-2023.png",
        },{
          name: "Hussain Tayabb",
          course: "Wave Analysis & Trading",
          enrolled: "April 2023",
          review: "My journey into the stock market started in 2022, just like most people, I also started my journey with tips and stock picks from friends, but that story never lasts for long, the quick and early gains simply get washed away.<br/>" +
            "<br/>" +
            " However, I wished to approach the stock market as a business and trade it to make a living. I knew this would only be possible if my approach was scientific. <br/>" +
            "<br/>" +
            "For most people, including myself the challenge is what to learn, from who to learn and how to put the picture together so that consistent returns could be generated. <br/>" +
            "<br/>" +
            "After applying myself for many hours and learning from different people, I was fortunate to make contact with Pawan ji. He is a learned tutor, guide and now a friend. I can say that joining the wave analysis course was probably one of the best decision i have made. <br/>" +
            "<br/>" +
            "Pawan ji has divided the course in three parts, he starts with the basics which make a firm and solid foundation, you learn the patterns and the challenges of each pattern. The second part is where he pushes the student to mark on indexes from a single higher time frame and then drill down to the smaller time frames, this exercise pushes students to apply the rules learnt and solves the question on how to put the picture together. Finally, observing live charts and trading them as per your psychology (scalper, swing player, positional etc.). As a tutor he covers the subject in dept he is approachable, and he does not shy away from giving personal attention.  <br/>" +
            "<br/>" +
            "it has been a tremendous learning experience for me, and I would recommend this course to those who wish to learn wave analysis. <br/>" +
            "<br/>" +
            /*"Do you have any suggestions for improving the Wave Analysis course? Any topics or techniques you would like to see covered in more detail?<br/>" +
            "<br/>" +
            "Live trading could be included in the course and some live trades could also be shared to give students a boost in taking positions, I believe this would be a welcome addition the the course, especially for those who are scalpers and wish to make a change to positional trading. <br/>" +
            "<br/>" +*/
            "In addition, I would like to suggest, Wave Analysis is life changing you learn where you are at present in the market and a high probability to where the market is likely to go, the journey need not be in isolation hence making a weekly meeting for existing and past students who wish to stay a part of the intellxis family would be superb.  like the forecasting initiative.<br/>" +
            "<br/>" +
            "Growth happens when we join people—joining people could be affordable.",
          photo: "assets/img/testimonial/hussaintayabb-april-2023.png",
        },  {
          name: "Anil Kumar (IITB)",
          course: "Wave Analysis & Trading",
          enrolled: "April 2023",
          review: "It is my pleasure to share the learning experience with Intellxis training. I have been part of three modules (Wave Analysis, Advance F&O, and Forecasting service). I am highly impressed with the knowledge of Pawan Sir and his grip on wave analysis and forecasting the future trend. He has an amazing knack of seeing how the future prices will move. He is an excellent teacher, and shares all his knowledge without hesitation. At times, I felt overwhelmed as was doing all the three modules learning simultaneously.  However, the availability of the class recording was very helpful to catch up as and when I had more time. I was an advance practitioner of Elliott Wave Analysis before joining his classes, but was struggling with wave labeling and forecasting because of the absence of time element. Therefore, it was very frustrating to keep on changing the wave labels based on what happens in future. However, Wave Analysis training by Pawan sir was incredible as I am very confident in labeling waves now. The best part is that none of the past labels need to be changed ever as Wave Analysis is highly rule based and there is no subjectivity. Pawan sir taught us so many tricks to trade which include momentum, thrust, how to identify X waves and so on. <br/>" +
            "<br/>" +
            "Advance F&O learning for trading took my financial journey to another level. It is a completely automated system wherein you have to observe and stay along as per the system. It gives wonderful results. In fact, we can recover the full training fee during the training itself.<br/>" +
            "<br/>" +
            "Overall, I rate this training as one of the best I have come across and will highly recommend to anyone who wants to see a material shift in their trading and wave analysis.",
          photo: "assets/img/testimonial/anilkumar-april-2023.png",
        }, {
          name: "Vikram Joshi",
          course: "Wave Analysis & Trading",
          enrolled: "April 2022",
          review: "When I started the Advance Wave Analysis & Trading course, I was a bit skeptical about the amount of technical jargon involved. But Pawan Sir made the entire journey seamless and educational. He doesn't just teach you the theory; he brings in his own market experiences, explaining how to practically apply Wave Analysis techniques. The regular assignments and end-of-module tests are challenging yet invaluable for self-assessment. I think what sets Intellxis apart is the ongoing support; you're not just left to fend for yourself once the course is over. The support team is available to assist with questions even after course completion, which is simply amazing!",
          photo: "assets/img/testimonial/testimonials.png",
        },{
          name: "Kavya Iyer",
          course: "Wave Analysis & Trading",
          enrolled: "July 2022",
          review: "The Wave Analysis course was recommended to me by a friend, and I must say it's been one of the most transformative learning experiences I've had. The course layout is superb, gradually taking you from basics to complex wave structures and market patterns. I was amazed at how Pawan Sir has this uncanny ability to break down complex concepts into bite-sized, understandable pieces. The weekly quizzes and periodic assessments are great for gauging your progress. One thing I'd suggest is maybe adding a few more case studies from global markets, but that's just to add an international flavor to an otherwise stellar course.",
          photo: "assets/img/testimonial/testimonials.png",
        },],
      feeDetails: [{
        price: "₹1,10,000",
        oldPrice: "₹1,60,000",
        duration: "3 Months",
        language: "English & Hindi",
        offer: "33%",
        live: true,
        recording: true,
        plugin: {
          access: true,
          name: "WaveChart Plugin",
          validity: "1 Years",
        },
        forecasting: {
          access: false,
          validity: "1 Months"
        },
        support: "12 Months",
        bestValue: true,
        payment: {
          type: 'UPI', // UPI, ONLINE
          link: 'https://pages.razorpay.com/pl_Mdv8QrHwcYysrT/view',
          upi: 'intellxis@hdfcbank (INTELLXIS LLP)',
          limitedTime: true
        },
      }],
      newBatch: {flag: true, startDate: "April 2025"},
      other: []
    },
    // F&O Trading
    {
      id: 5,
      uri: "NRT-Trading",
      img: "assets/img/course/fnotrading.jpg", //fnotrading
      sessions: "12+",
      title: "NRT (F&O) Trading",
      subtitle: "1 Year Mentorship program",
      description: "Mentorship to Navigate Trading Strategies with Confidence",
      category: "Mentorship Program",
      color: "orange",
      details: "The most significant challenge in Future and Option trading is selecting the appropriate option strategy. While there are various option strategies available, traders often struggle to determine the most suitable strategy at the right time.<br/><br/>" +
        "At Intellxis, we offer a mentorship program for traders interested in Future and Option trading. The program is designed to help traders develop their skills in selecting the appropriate option strategy, managing risk, and adapting to changing market conditions.<br/><br/>" +
        "Our mentorship program provides one-on-one coaching with experienced traders who will guide you through the intricacies of Future and Option trading. They will help you develop a trading plan that aligns with your goals and risk tolerance and teach you how to select the most suitable option strategy based on market conditions.<br/><br/>" +
        "Additionally, our mentorship program covers risk management techniques, including setting stop-loss orders and using options strategies like buying puts or selling calls to hedge your positions. Our mentors will also teach you how to adapt to changing market conditions and adjust your trading plan accordingly.<br/><br/>" +
        "As part of the mentorship program, we provide unique tools* and comprehensive learning materials to support your development as a trader. With our mentorship program, you will have the support and guidance you need to become a successful Future and Option trader.<br/><br/>" +
        "By completing our mentorship program, you'll have a strong foundation in options trading and the ability to apply advanced strategies used by professional traders. You'll have the confidence to make informed trading decisions and manage risk effectively, positioning you for success in the options market.",
      courseDelFaqs: [
        {
          que: "What is the main objective of the Future and Option Trading Mentorship Program?",
          ans: "The mentorship program aims to help traders develop their skills in selecting the appropriate option strategy, managing risk, and adapting to changing market conditions in the realm of Future and Option trading."
        }, {
          que: "Who is the Intended Audience?",
          ans: "This program is ideal for traders interested in Future and Option trading, whether they're beginners or have some experience but want to enhance their skill set.",
        }, {
          que: "What unique tools will I have access to during the mentorship?",
          ans: "As part of the program, we provide unique tools and comprehensive learning materials to support your development as a trader. Specific details about these tools can be obtained from the course coordinator."
        }, {
          que: "How will this mentorship help improve my risk management?",
          ans: "Our program covers risk management techniques, such as setting stop-loss orders, controlling risk on entry, and using options strategies like buying puts or selling calls to hedge your positions."
        }, {
          que: "Can I adapt my trading plan according to the market?",
          ans: "Yes, our mentors will teach you how to adapt to changing market conditions and adjust your trading plan accordingly."
        }, {
          que: "By the end of the program, what will I have achieved?",
          ans: "By the end of our mentorship program, you will have a robust foundation in options trading, the ability to apply advanced strategies used by professionals, and the confidence to make informed trading decisions."
        }, {
          que: "Is there any post-mentorship support provided?",
          ans: "Yes, we continue to provide resources and tools to our alumni. Specific post-mentorship benefits can be discussed with the course coordinator."
        }, {
          que: "Are there any prerequisites for joining the program?",
          ans: "There are no strict prerequisites, but a basic understanding of trading can be beneficial. If you're entirely new to trading, you might want to reach out to our team to determine if the program is a good fit for you."
        }
      ],
      curriculum: [
        "Acquire the skill to objectively identify market trends without relying on forecasts.",
        "Understand the diverse range of option strategies and learn how to choose the most suitable one based on changing market conditions.",
        "Master the art of letting the market dictate your entry points, enhancing your trading precision.",
        "Learn techniques for proficiently managing risk, including setting stop-loss orders, controlling risk at entry, and deploying hedging strategies.",
        "Develop a tailored trading plan that aligns precisely with your individual goals and risk tolerance.",
        "Learn to manage your positions objectively after entering the market, swiftly exit unprofitable positions, and optimize the accumulation of profits from winning trades.",
        "Skillfully exit losing positions promptly while allowing profitable ones to grow, enhancing overall profitability.",
        "Work towards achieving a profit-to-loss ratio exceeding 3-to-1 over time through the application of effective trading strategies.",
        "Dive deep into specific options strategies, such as buying puts and selling calls, and grasp the pivotal advantages of Options trading as an essential tool for professional traders.",
        "Access exclusive tools and comprehensive resources as part of the mentorship program to enrich your trading knowledge and capabilities.",
      ],
      testimonials: [
        {
          name: "Rahul Mehta",
          course: "F&O Trading",
          enrolled: "July 2022",
          review: "This course is a game-changer. Integrating wave analysis with F&O trading is a brilliant move. Before taking up this course, I had foundational knowledge but was missing a cohesive strategy. The real-time examples, case studies, and quizzes have been instrumental in building my confidence. Pawan Sir is not just a teacher but a mentor who is willing to go the extra mile to ensure that every student grasps the concepts. His patience and ability to simplify complex strategies are truly commendable. While the course is packed with information, the class recordings make it easy to revisit concepts and catch up. I couldn't be happier with my decision to enroll.",
          photo: "assets/img/testimonial/testimonials.png",
        }, {
          name: "Priya Verma",
          course: "F&O Trading",
          enrolled: "April 2022",
          review: "I've had some experience in trading before, but the Advance F&O Trading course took my understanding to a different level. The course is incredibly well-structured, covering both fundamental and advanced strategies. What stood out for me was how Pawan Sir keeps the class interactive; it's not just about passively listening to lectures. We get opportunities to work on live trading scenarios, which builds confidence and sharpens skills. The class recordings are a godsend for someone like me who occasionally had to miss the live sessions due to work commitments. Also, the resource materials are extremely detailed, allowing for a deeper dive into the subject. All in all, a five-star experience!",
          photo: "assets/img/testimonial/testimonials.png",
        }, {
          name: "Anil Kumar",
          course: "F&O Trading",
          enrolled: "April 2023",
          review: "It is my pleasure to share the learning experience with Intellxis training. I have been part of three modules (Wave Analysis, Advance F&O, and Forecasting service). I am highly impressed with the knowledge of Pawan Sir and his grip on wave analysis and forecasting the future trend. He has an amazing knack of seeing how the future prices will move. He is an excellent teacher, and shares all his knowledge without hesitation. At times, I felt overwhelmed as was doing all the three modules learning simultaneously.  However, the availability of the class recording was very helpful to catch up as and when I had more time. I was an advance practitioner of Elliott Wave Analysis before joining his classes, but was struggling with wave labeling and forecasting because of the absence of time element. Therefore, it was very frustrating to keep on changing the wave labels based on what happens in future. However, Wave Analysis training by Pawan sir was incredible as I am very confident in labeling waves now. The best part is that none of the past labels need to be changed ever as Wave Analysis is highly rule based and there is no subjectivity. Pawan sir taught us so many tricks to trade which include momentum, thrust, how to identify X waves and so on. <br/>" +
            "<br/>" +
            "Advance F&O learning for trading took my financial journey to another level. It is a completely automated system wherein you have to observe and stay along as per the system. It gives wonderful results. In fact, we can recover the full training fee during the training itself.<br/>" +
            "<br/>" +
            "Overall, I rate this training as one of the best I have come across and will highly recommend to anyone who wants to see a material shift in their trading and wave analysis.",
          photo: "assets/img/testimonial/anilkumar-april-2023.png",
        }, {
          name: "Milind Gulavani",
          course: "F&O Trading",
          enrolled: "July 2023",
          review: "I have been trading in the market for the last 3-4 years and have done multiple courses. I have been a follower of elliott wave and techniques used in Advanced trading. I can confidently say that this is the best set of courses that I have come across over the years. Pawan has a very deep understanding of the market and the toolkit he has developed is amazing. Teaching is his passion and understanding the market deeply is his forte. This is a course for good duration of 3 months but Pawan doesnt rush into it unless everyone in the class is clear on concepts and applications of the same. He forces students to practise and ensures that they come up with doubts. The course moves at a very good pace giving enough room to understand, study, practise and clarify doubts. But one has to put in efforts to reap benefits. Pawan is there to help everyone and answers every single doubt, not only in the class but otherwise offline as well. He goes beyond the course duration to ensure the success of his students. It has been great taking these courses and I would recommend these courses to everyone who is serious about making money in the market.\n" +
            " However, I recommend to take these courses in sequence. Making money in the markets is not easy and it requires efforts so its better to learn Advanced trading course first. Start making money, understand the markets and then go to the elliott wave course. That way you would be able to give sufficient time to learn and start making money which is very important to boost the confidence in the markets. ",
          photo: "assets/img/testimonial/milind-july-2023.jpg",
        }, {
          name: "Sunil Patel",
          course: "F&O Trading",
          enrolled: "July 2023",
          review: "This course is a game changer for me where you will be able to control emotions to your trade.<br/>" +
            "<br/>" +
            "Perfectly developed and taught by Mr. Pawan/Intellxis team. He is giving more than 100% to teach these concepts very well.<br/>" +
            "<br/>" +
            "Mainly, we as retailers fall into the below situation, and we are selling our stock.<br/>" +
            "<br/>" +
            "1. Stock is sideways after breakout/runup and not moving since many weeks — So in frustration, we will sell it.<br/>" +
            "2. Stock has created panic with any negative news, and we sell it due to fear and public noise.<br/>" +
            "3. Stock is moving in trendy fashion after a long time, but we are not able to capture the trend.<br/>" +
            "<br/>" +
            "Believe me, NRT with Mr Pawan will resolve all above problems. Definitely, you have to do homework. It will be straightforward. He has developed his own indicator to apply NRT rules on each stock. Also, he has developed a screener on it; it is saving a lot of time for scanning stocks.<br/>" +
            "<br/>" +
            "I did NEoWave and NRT both with Mr. Pawan/Intellxis. Amazing experience with both and long journey. If you want to enhance yourself with learning, NRT is a nice concept and well-taught by Mr. Pawan and Intellxis team.",
          photo: "assets/img/testimonial/sunilpatel-april-2023.png ",
        },],
      feeDetails: [
        {
          oldPrice: "₹1,90,000",
          price: "₹1,11,000",
          duration: "3 Months",
          language: "English & Hindi",
          offer: "33%",
          live: true,
          recording: true,
          forecasting: {
            access: false,
            validity: "1 Months"
          },
          plugin: {
            access: true,
            name: "NRT Plugin",
            validity: "1 Year",
          },
          support: "12 Months",
          bestValue: true,
          payment: {
            type: 'UPI', // UPI, ONLINE
            link: 'https://pages.razorpay.com/pl_N1vDCbMxOT2eek/view',
            upi: 'intellxis@hdfcbank (INTELLXIS LLP)',
            limitedTime: true
          }
        },],
      newBatch: {flag: true, startDate: "April 2025"},
    },
    // Mastering the Art of Trading
    {
      id: 6,
      uri: "Trading-Psychology",
      img: "assets/img/course/psychology.jpg",
      sessions: "8+",
      title: "Mastering the Art of Trading",
      subtitle: "Mentorship program",
      description: "A Comprehensive Guide on Market Psychology",
      category: "Mentorship Program",
      color: "blue-2",
      details: "Intellxis presents the 'Master the Art of Trading' program, where you'll build a rock-solid foundation, decode trade outcomes, and grasp the intricate decision-making process. Dive deep into the human brain's information processing, unravel psychological influences, and master a unique framework for trading success. Discover your default trading style aligned with your behavior for trading mastery.",
      courseDelFaqs: [
        {
          que: "What is the 'Master the Art of Trading' program offered by Intellxis?",
          ans: "The 'Master the Art of Trading' program is an intensive course by Intellxis designed to help traders build a strong foundation, understand trading analysis, and master the decision-making process in trading. It also explores the psychological aspects of trading and helps participants discover their default trading style."
        }, {
          que: "Who is this program suitable for?",
          ans: "This program is suitable for both novice and experienced traders who want to enhance their trading skills, improve decision-making, and gain insights into the psychological aspects of trading.",
        }, {
          que: "What topics are covered in the course?",
          ans: "The course covers a wide range of topics, including establishing a strong foundation, trading analysis, decision-making processes, human brain information processing, psychological influences on decision-making, and discovering your default trading style based on behavior."
        }, {
          que: "Is there ongoing support after completing the course?",
          ans: "Intellxis typically offers ongoing support to participants even after they complete the course. This support may include access to resources, trading communities, or additional learning opportunities."
        },
      ],
      curriculum: [
        "Establishing a Strong Foundation.",
        "Unraveling the Art of Trading Analysis.",
        "Comprehending the Trading Decision-Making Process.",
        "Mastery of Decision-Making in Trading.",
        "Exploring the Human Brain's Information Processing.",
        "In-Depth Analysis of Decision-Shaping Factors.",
        "Exploring the Psychological Influences on Decision-Making.",
        "Mastering a Distinct Framework for Trading Success.",
        "Discovering Your Default Trading Style Based on Behavior.",
      ],
      testimonials: [
        {
          name: "Rahul Mehta",
          course: "Master the Art of Trading",
          enrolled: "July 2022",
          review: "I've been trading for years, but it wasn't until I enrolled in the \"Master the Art of Trading\" program that I truly began to understand the intricacies of the market. This program is a game-changer. It delves deep into the psychology of trading, helping you navigate the emotional rollercoaster that comes with it. The instructors are not just educators; they're mentors who genuinely care about your success. The unique framework they provide has transformed my trading style, and I'm now trading with more confidence and discipline than ever before. Highly recommended!",
          photo: "assets/img/testimonial/testimonials.png",
        }, {
          name: "Ananya Patel",
          course: "Master the Art of Trading",
          enrolled: "July 2022",
          review: "As a complete novice to trading, I was overwhelmed by the complexity of the financial markets. The \"Master the Art of Trading\" program was my lifeline. It starts with the basics, making it accessible for beginners like me. The instructors are patient and knowledgeable, guiding you every step of the way. What sets this program apart is its focus on the psychological aspects of trading. I've learned to manage my emotions and make more rational decisions. I can't thank the program enough for turning me from a novice into a knowledgeable trader.",
          photo: "assets/img/testimonial/testimonials.png",
        }, {
          name: "Rajesh Kapoor",
          course: "Master the Art of Trading",
          enrolled: "July 2022",
          review: "I've taken several trading courses in the past, but none have been as comprehensive as the \"Master the Art of Trading\" program. It covers not only the technical aspects but also the psychological side of trading. Understanding how our minds work and how emotions influence our decisions has been a game-changer for me. The program's unique framework simplifies trading and helps you find your trading style. It's a holistic approach that has transformed the way I trade. I highly recommend it to traders of all levels.",
          photo: "assets/img/testimonial/testimonials.png",
        }, {
          name: "Priya Sharma",
          course: "Master the Art of Trading",
          enrolled: "July 2022",
          review: "I've always been intrigued by trading but felt overwhelmed by the sheer complexity of it. This program has been enlightening. It takes you on a journey, starting from the basics and gradually building your knowledge and confidence. The instructors are passionate and supportive, always ready to answer questions and provide guidance. What I appreciate most is the focus on the psychology of trading. I've become more aware of my emotions and how they impact my decisions. This program has empowered me to make smarter, more informed trades. It's been a transformative experience.",
          photo: "assets/img/testimonial/testimonials.png",
        }, {
          name: "Amit Desai",
          course: "Master the Art of Trading",
          enrolled: "July 2022",
          review: "Trading used to be a realm of uncertainty for me, filled with doubt and anxiety. The \"Master the Art of Trading\" program has changed that entirely. It provides a clear and structured approach to trading, from understanding the basics to mastering decision-making. The instructors are not just knowledgeable but incredibly supportive. They create a learning environment where you feel comfortable asking questions and making mistakes. The psychological insights are invaluable, helping me manage my emotions and trade with confidence. This program has been a revelation, and I couldn't be happier with the results.",
          photo: "assets/img/testimonial/testimonials.png",
        },],
      feeDetails: [{
        oldPrice: "₹65,000",
        price: "₹25,000",
        duration: "2 Months",
        language: "English & Hindi",
        offer: "33%",
        live: true,
        recording: true,
        forecasting: {
          access: false,
          validity: ""
        },
        plugin: {
          access: false,
          name: "",
          validity: "",
        },
        support: "",
        bestValue: true,
        payment: {
          type: 'UPI', // UPI, ONLINE
          link: 'https://pages.razorpay.com/trading-psychology-course',
          upi: 'intellxis@hdfcbank (INTELLXIS LLP)',
          limitedTime: true
        }
      }],
      other: [],
      newBatch: {flag: true, startDate: "May 2025"},
    },
  ];

  public getCourses(): CourseI[] {
    return this.courses;
  }

  ngOnInit(): void {
  }
}
