import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-plugin-fno',
  templateUrl: './plugin-fno.component.html',
  styleUrls: ['./plugin-fno.component.scss']
})
export class PluginFnoComponent implements OnInit {

  youtube : string[] = [
    "AKEf5dhGnuA", "Fu76wSQK95Q", "3h5Pyb-jUTQ",
    "AwPV_Xg47rA", "yH2QL4_rm0A", "zuYFLbIC-gA",
    "RxoBx2Jddo4", "Et-H_pbuQRk",
  ];


  plugin = [{
    oldPrice: "₹25,000",
    price: "₹19,999",
    period: "1 Month",
    payment: "", // https://pages.razorpay.com/pl_LQA6jdovO7zl83/view
    upi: "intellxis@hdfcbank,INTELLXIS LLP",
    msg: "* You can subscribe for one month trial version only once."
  }, {
    oldPrice: "₹48000",
    price: "₹39,999",
    period: "6 Month",
    payment: "", //https://pages.razorpay.com/pl_LQA5uazR3nUn4r/view
    upi: "intellxis@hdfcbank,INTELLXIS LLP",
    msg: "Subscribe our premium version of NRT plugin for 6 Months."
  }, {
    oldPrice: "₹96000",
    price: "₹49,999",
    period: "12 Month",
    payment: "", // https://pages.razorpay.com/pl_KPwrH2FYlPag2o/view
    upi: "intellxis@hdfcbank,INTELLXIS LLP",
    msg: "Subscribe our premium version of NRT plugin for 12 Months."
  }];

  constructor(private sanitizer: DomSanitizer, public changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
  }

  public getSafeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}
